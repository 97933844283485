import { grey, teal, deepPurple } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const primaryColor = "#01314a";
const dropZoneColor = "#f2f8fc";
const successColor = "#009874";
const errorColor = "#ad1919";

/**
 * Theme color palette extensions
 */
export interface Colors {
  //warningOrangeLight: string;
  error: string;
  grey30: string;
  grey70: string;
  grey80: string;
  grey96: string;
  neutral2: string;
  orange: string;
  success: string;
  warningOrange: string;
  activeLightBlue: string;
  linkBlue: string;
  activeBlue: string;
}

export const colors: Colors = {
  error: errorColor,
  neutral2: "#C8C8C9",
  warningOrange: "#FFF7EC",
  orange: "#F2A328",
  success: successColor,
  grey30: "#4d4d4d",
  grey70: "#b3b3b3",
  grey80: "#ccc",
  grey96: "#f5f5f5",
  activeLightBlue: "#f2f8fc",
  linkBlue: "#105990",
  activeBlue: "#0083c8",
};

export const theme = createTheme({
  palette: {
    mode: "light",
    claimStatusOfferBlue: {
      main: "rgba(125, 184, 229, 0.2)",
      dark: "rgba(125, 184, 229, 0.33)",
      contrastText: "#105990",
    },
    claimStatusOfferYellow: {
      main: "#FFFAE9",
      dark: "rgba(201, 136, 35, 0.2)",
      contrastText: "#7C4904",
    },
    claimStatusOfferGreen: {
      main: "rgba(118, 228, 175, 0.3)",
      dark: "rgba(0, 152, 116, 0.2)",
      contrastText: "#115B42",
    },
    claimStatusOfferRed: {
      main: "rgba(246, 66, 77, 0.12)",
      dark: "rgba(234, 22, 22, 0.14)",
      contrastText: "#AD1919",
    },
    claimStatusOfferNeutral: {
      main: "rgba(40, 41, 60, 0.12)",
      dark: "rgba(40, 41, 60, 0.2)",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    claimStatusOfferNeutralLight: {
      main: "#F5F5F5",
      dark: "rgba(51, 51, 51, 0.1)",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    chipPrimary: {
      main: "rgba(125, 184, 229, 0.2)",
      light: "rgba(125, 184, 229, 0.2)",
      dark: "rgba(125, 184, 229, 0.33)",
      contrastText: "#105990",
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: "#001C3B",
      light: "#808080",
    },
    background: {
      default: "#fff",
      dropZoneColor: dropZoneColor,
      dropZoneBorder: "#7DB8E5",
      listItemBorder: "#e5e5e5",
    },
    info: {
      main: grey[300],
      dark: "#666",
    },
    colors,
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    button: {
      textTransform: "none",
    },
    xxxlHeading: {
      fontSize: "40px",
      lineHeight: "52px",
      fontWeight: "500",
    },
    xxxlBody: {
      fontSize: "40px",
      lineHeight: "52px",
      fontWeight: "400",
    },
    xxlHeading: {
      fontSize: "32px",
      lineHeight: "40px",
      fontWeight: "500",
    },
    xxlBody: {
      fontSize: "32px",
      lineHeight: "40px",
      fontWeight: "400",
    },
    xlHeading: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: "500",
    },
    xlBody: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: "400",
    },
    lHeading: {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: "500",
    },
    lBody: {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: "400",
    },
    mHeading: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "500",
    },
    mBody: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "400",
    },
    sHeading: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: "500",
    },
    sBody: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: "400",
    },
    xsHeading: {
      fontSize: "12px",
      lineHeight: "20px",
      fontWeight: "600",
    },
    xsBody: {
      fontSize: "12px",
      lineHeight: "20px",
      fontWeight: "400",
    },
    xxsHeading: {
      fontSize: "10px",
      lineHeight: "16px",
      fontWeight: "600",
    },
    xxsBody: {
      fontSize: "10px",
      lineHeight: "16px",
      fontWeight: "400",
    },

    // Disabling default mui variants to prevent usage outside of design system
    h1: undefined,
    h2: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    caption: undefined,
    overline: undefined,
  },
  components: {
    MuiCircularProgress: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
      defaultProps: {
        size: "medium",
        fullWidth: true,
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          minWidth: 0,
          fontSize: "1.25rem",
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: "primary",
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "2rem",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: "primary",
        disableElevation: true,
        disableRipple: true,
        variant: "contained",
      },
      styleOverrides: {
        root: {
          borderRadius: "2rem",
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: "lg",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        color: "secondary",
        size: "small",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        color: "secondary",
        size: "small",
      },
    },
    MuiAccordion: {
      variants: [
        {
          // @ts-ignore TODO: see theme-overrides
          props: { variant: "borderless" },
          style: {
            textTransform: "none",
            border: `none`,
            boxShadow: "none",
            "&::before": {
              content: "unset",
            },
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { variant: "teal" },
          style: {
            backgroundColor: teal[50],
            border: `1px solid ${teal[900]}`,
            color: teal[900],
          },
        },
        {
          props: { variant: "deepPurple" },
          style: {
            backgroundColor: deepPurple[50],
            border: `1px solid ${deepPurple[900]}`,
            color: deepPurple[900],
          },
        },
      ],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 3,
  },
});
