import { ReactNode, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { translationMap } from "./translationMap";

interface TranslationsProps {
  children: ReactNode;
}

/**
 * Service wrapper that adds localization support
 * @param props
 * @returns ReactNode
 */
export function Translations({ children }: TranslationsProps) {
  const [language, setLanguage] = useState<string>("en");
  useEffect(() => {
    setLanguage(navigator.language.split(/[-_]/)[0] || "en");
  }, []);
  return (
    <IntlProvider locale={language} messages={translationMap[language]}>
      {children}
    </IntlProvider>
  );
}
