import translations_en from "./en.json";

export type translationKeys = keyof typeof translations_en;

export type TranslationsMessages = {
  [key in translationKeys]: string;
};

export type TranslationMap = {
  [key: string | symbol]: TranslationsMessages;
};

export const translationMap: TranslationMap = {
  en: translations_en,
};
