import type { Props as ReactIntlFormattedMessageProps } from "react-intl/src/components/message";
import { FormattedMessage as ReactIntlFormattedMessage } from "react-intl";
import { translationKeys } from "./translationMap";

// Extend the original FormattedMessage props.
type FormattedMessageProps = ReactIntlFormattedMessageProps<
  Record<string, any>
> & {
  id?: translationKeys;
};

export function FormattedMessage({ id, ...rest }: FormattedMessageProps) {
  return <ReactIntlFormattedMessage id={id} {...rest} />;
}
