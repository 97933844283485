import translations_en from "./en.json";
import { translationKeys } from "./translationMap";

type translationsMessages = {
  [key in translationKeys]: string;
};

export class TranslationMapper {
  private readonly messages: translationsMessages;

  constructor(messages: translationsMessages) {
    this.messages = messages;
  }

  exists(key: string): boolean {
    return this.messages.hasOwnProperty(key);
  }

  string(key: translationKeys, values: { [key: string]: string } = {}): string {
    let value = this.messages[key];

    Object.keys(values).forEach((key) => {
      value = value.replace(`{${key}}`, values[key]);
    });

    return value;
  }
}

type translationsOptions = {
  [key: string | symbol]: translationsMessages;
};

export const translations: translationsOptions = {
  en: translations_en,
};
